




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/vessel-files/vessel-files";

@Component
export default class VesselFilesWrapper extends Vue {
  instance: any = {};

  mounted(): void {
    const currentVessel = this.$store.getters["Vessels/currentVessel"];
    const param = {
      vesselId: currentVessel?.id,
      vesselname: currentVessel?.name,
    };

    this.instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;
    ko.applyBindings(this.instance, this.$el);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
