






















import { Component, Vue } from "vue-property-decorator";
import vesselFilesWrapper from "@/components/knockoutWrappers/VesselFilesWrapper.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import featureNotAvailable from "@/components/FeatureNotAvailable.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import events from "App/events";
import vesselFilesClient from "@/Scripts/clients/vessel-files-client";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    vesselFilesWrapper,
    PdfViewer,
    featureNotAvailable,
  },
})
export default class VesselFiles extends Vue {
  vessel = Vessels.currentVessel;
  viewPdfBinding: any = null;
  closePdfBinding: any = null;
  vesselFileDownloadBinding: any = null;
  pdfSrc: any = "";
  downloadUrl = "";
  pdfTitle = "Vessel document";

  get featureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "Documents");
  }

  mounted(): void {
    //pdf is dispatched from vessel-files.js:156
    this.viewPdfBinding = events.viewPdf.add((pdf: any) => {
      this.updatePdf(pdf);
    });

    this.closePdfBinding = events.closePdf.add(() => {
      this.pdfSrc = "";
    });

    this.vesselFileDownloadBinding = events.vesselFileDownload.add((file: any) => {
      vesselFilesClient.download(file.id);
    });
  }

  beforeDestroy(): void {
    this.viewPdfBinding.detach();
    this.closePdfBinding.detach();
    this.vesselFileDownloadBinding.detach();
  }

  updatePdf(pdf: any): void {
    vesselFilesClient.getFileUrl(pdf.id).then(data => {
      this.pdfSrc = data;
      this.downloadUrl = "/VesselFiles/" + pdf.id;
    });
  }
}
